@charset "utf-8";
/* CSS Document */

:root {
  --Primarygreen: #0a682e;
  --PrimaryBlack: #231f20;
  --PrimaryWhite: #ffffff;
  --PrimaryBlue: #16499b;
  --SecondaryBlue: #6083bb;
  --SecondaryYellow: #e99d28;
  --SecondaryGreen: #a2ce54;
  --SecondaryGray: #828282;
  --PrimaryFonts: "Chica Gogo", Sans-serif;
}

/*Link*/
a {
  color: var(--PrimaryBlue);
}

a:hover {
  color: var(--SecondaryBlue);
}

.page-link {
  color: var(--SecondaryBlue);
}

.page-link:hover {
  color: var(--SecondaryBlue);
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: var(--PrimaryBlue);
  border-color: var(--PrimaryBlue);
}

.page-item.disabled .page-link {
  color: #8898aa;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

/*Backgrounds*/
.bg-default {
  background-color: var(--PrimaryBlue) !important;
}
.bg-default-secondary {
  background-color: var(--PrimaryBlue) !important;
}

.bg-gradient-mix {
  background: var(--PrimaryBlue) !important;
}
.bg-gradient-primary {
  background: var(--PrimaryWhite) !important;
}

.fill-default {
  fill: var(--PrimaryBlue);
}

/*Buttons*/
.btn-primary {
  color: var(--PrimaryWhite);
  background-color: var(--PrimaryBlue);
  border-color: var(--PrimaryBlue);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.btn-primary:hover {
  color: var(--PrimaryWhite);
  background-color: var(--PrimaryBlue);
  border-color: var(--PrimaryBlue);
  opacity: 0.9;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: var(--PrimaryBlue);
  border-color: var(--PrimaryBlue);
  opacity: 1;
}

/*Checkbox*/
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--PrimaryBlue);
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--PrimaryBlue);
}
.custom-control-input:active ~ .custom-control-label::before {
  background-color: var(--PrimaryBlue);
}

/*radio*/

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--PrimaryBlue);
  background-color: var(--PrimaryBlue);
}

.custom-radio .custom-control-input:disabled ~ .custom-control-label::before {
  border-color: #e9ecef;
}

.custom-radio .custom-control-input:disabled:checked::before {
  border-color: rgba(94, 114, 228, 0.5);
}

.custom-toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 1.5rem;
}

.custom-toggle input {
  display: none;
}

.custom-toggle input:checked + .custom-toggle-slider {
  border: 1px solid var(--PrimaryBlue);
}

.custom-toggle input:checked + .custom-toggle-slider:before {
  background: var(--PrimaryBlue);
  transform: translateX(1.625rem);
}

.custom-toggle input:disabled + .custom-toggle-slider {
  border: 1px solid #e9ecef;
  cursor: not-allowed;
}

.custom-toggle input:disabled:checked + .custom-toggle-slider {
  border: 1px solid #e9ecef;
  cursor: not-allowed;
}

.custom-toggle input:disabled:checked + .custom-toggle-slider:before {
  /* background-color: #8a98eb; */
  background-color: #e9ecef;
}

.custom-toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #cad1d7;
  border-radius: 34px !important;
  background-color: transparent;
}

.custom-toggle-slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 2px;
  border-radius: 50% !important;
  background-color: #e9ecef;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.navbar-nav ul {
  padding: 0px;
  margin: 0px;
}
.navbar-nav ul li a {
  font-size: 13px;
  color: #828282;
}
.navbar-nav ul li a span {
  padding-left: 2px;
  padding-right: 20px;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: var(--PrimaryBlue);
}

.file {
  visibility: hidden;
  position: absolute;
}
#preview {
  max-width: 100px;
  max-height: 100px;
}
.dropdown-item.active {
  color: #16181b;
  text-decoration: none;
  background-color: #f6f9fc;
}

.box-shadow{
  box-shadow: 0 4px 6px rgba(22, 73, 155, 0.5);

}
.dmt--7{
  margin-top: -9rem !important
}


.upload_dropZone {
  color:  #0B2447;
	border-radius: 10px;
  background-color:  #E4F6FF;
  outline: 2px dashed #14B4AC;
  outline-offset: -12px;
  transition:
    outline-offset 0.2s ease-out,
    outline-color 0.3s ease-in-out,
    background-color 0.2s ease-out;
}
.upload_dropZone.highlight {
  outline-offset: -4px;
  outline-color:#14B4AC;
  background-color: #E4F6FF;
}
.upload_svg {
  fill: #14B4AC;
}


.blue_btn2 {
  background:#16499b;
  color:  #E4F6FF;
  border:#16499b
}