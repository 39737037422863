.ant-table {
    font-size: .9375rem;
    color: rgb(51, 51, 51);
}
.ant-table-body{
  overflow-x: auto; 
}
.ant-table-thead > tr > th {
  background-color: rgba(255, 255, 255, 0.85);
  border-bottom: 2px solid #dee2e6;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td {
    border-right: 0px solid #f0f0f0;
    border-top: 1px solid #dee2e6;
}
/* .ant-pagination-options-size-changer.ant-select {
    display: none;
} */
td.ant-table-column-sort {
    background: rgba(0, 0 ,0 , 0);
}
.ant-table.ant-table td h2.table-avatar {
    align-items: center;
    display: inline-flex;
    font-size: inherit;
    font-weight: 400;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    white-space: nowrap;
}
.ant-table.ant-table td h2 {
    display: inline-block;
    font-size: inherit;
    font-weight: 400;
    margin: 0;
    padding: 0;
    vertical-align: middle;
}
.ant-table.ant-table td h2 a {
    color: #333;
}
.ant-table.ant-table td h2 span {
    color: #888;
    display: block;
    font-size: 12px;
    margin-top: 3px;
}
.ant-table-thead > tr , .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 10px 8px;
}
.ant-table.ant-table tr  {
    box-shadow: 0 0 3px #e5e5e5;
}
.ant-pagination {
    margin-top: 20px;
    text-align: end;
}
.ant-pagination-prev {
    display: none;
}
.ant-pagination-next {
    display: none;
}
.ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link, .ant-pagination-disabled:focus-visible .ant-pagination-item-link{
    border: none;
}

.ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next {
    color: rgb(108, 117, 125);
    font-size: 15px;
    height: 36px;
}
.ant-pagination-prev, .ant-pagination-jump-prev, .ant-pagination-jump-next {
    margin-right: 0px;
}
.ant-pagination-item {
    margin-right: 0px;
    padding: 1px 0px;
    height: 36px;
}

.ant-pagination-total-text {
    float: left;
    font-size: 15px;
}
.ant-pagination-item-active {
    background: var(--theme-color) !important;
    border-color: var(--theme-color) !important;
    border-radius: 5px !important;
    margin-right: 4px !important;
    color: #fff;
}
/* .ant-pagination-item-2{
    border-radius: 5px !important;
    background: #637381 !important;
    color: #fff !important;
} */
/* li.ant-pagination-item.ant-pagination-item-1{
    border-radius: 5px;
    background: #637381;
    color: #fff;
} */
/* li.ant-pagination-item.ant-pagination-item-2.ant-pagination-item-active{
    background: var(--theme-color) !important;
    border-color: var(--theme-color) !important;
    border-radius: 5px !important;
    margin-left: 4px !important;
} */
.ant-table-pagination-right {
    display: flex;
    position: relative;    
}
.ant-pagination-options{
    position: absolute;
    left: 0%;
}
[aria-selected*="true"]{
    background-color:var(--theme-color) !important ;
    color: #fff !important;
}
.ant-table.ant-table tr {
    box-shadow: none !important;
}
.table thead {
    background: #FAFBFE !important;
    border-bottom:none !important;
}
.ant-table-thead > tr > th {
    background: #FAFBFE !important;
    border-bottom:none !important;
}
.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
    width: 0px !important;
}
/* antd table-----------------------------------------*/
.ant-table-thead > tr > th{
    color: #858796 !important;
    font-weight: 600 !important;
    background: #f6f9fc !important;
  }
  .ant-table-tbody > tr > td{
    border-bottom: unset !important;
  }
  .ant-table{
    color: #525f7f
  }
  .ant-table-pagination.ant-pagination{
    margin: 16px 11px 16px 0 !important;
  }
  .ant-pagination-item{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0 !important;
    margin: 0 3px !important;
    border-radius: 50% !important;
    width: 36px !important;
    height: 36px !important;
    font-size: 0.875rem !important;
    color: var(--SecondaryBlue);
  }
 
  .ant-pagination-item:hover{
    z-index: 2;
    color: var(--PrimaryBlue);
    text-decoration: none;
    background-color: #dee2e6;
    border-color: #dee2e6;
  }
  .ant-pagination-item-active {
    background: var(--PrimaryBlue) !important;
    border-color: var(--PrimaryBlue) !important;
    border-radius: 50% !important;
    color: #fff !important;
  }
  .ant-pagination-total-text, .ant-select, .ant-select-item{
    color: #525f7f;
  }
/* box shadow for on scrollbar, for left and right section */
.ant-table-ping-left:not(.ant-table-has-fix-left) > .ant-table-container::before{
    box-shadow:none !important;
}
.ant-table-ping-right:not(.ant-table-has-fix-right) > .ant-table-container::after{
    box-shadow:none !important;
}
/* except action column */
.table tbody tr td:not(.action_column) {
    white-space: normal !important;
}
/* antd for empty data */
.ant-table-expanded-row-fixed{
    width: auto !important;
    margin: unset !important;
}
.ant-table-thead .ant-table-cell, .ant-table-column-title{
    /* font-size: 15px !important; */
}
.ant-table-tbody .ant-table-cell{
    /* font-size: 14px !important; */
}
.ant-button {
    outline: none !important;
}